import React from "react";
import { useState } from "react";
import { sendEmail } from "../Email Services/Email";
import Swal from "sweetalert2";

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(""); // tracking the status of the form submission

  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message, 
    };
    sendEmail(templateParams)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setStatus("Email sent successfully!");

        // Triggering SweetAlert2 for success
        Swal.fire({
          icon: "success",
          title: "Email Sent",
          text: "Your email has been sent successfully!",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline", 
          },
          background: "#000",
          color: "#fff",
          confirmButtonColor: "#98BC62",
        });

        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setStatus("Failed to send email. Please try again later.");

        // Triggering SweetAlert2 for error
        Swal.fire({
          icon: "error",
          title: "Failed to Send",
          text: "Failed to send email. Please try again later.",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline", 
          },
        });
      });
  };

  console.log(status);

  return (
    <>
      <style>
        {`
          .custom-popup-inline {
            border: 2px solid #98BC62 !important; /* Border color */
            border-radius: 15px !important; /* Rounded corners */
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important; /* Shadow */
            padding: 20px !important; /* Padding */
          }
        `}
      </style>
      <section className="whoWeAreSection">
        <div className="container additionalInfoSection">
          <div className="row">
            <div className="col-md-6">
              <h1 className="main-heading" style={{ fontSize: "50px" }}>
                Contact US
              </h1>
              <p className="sub-heading" style={{ fontSize: "18px" }}>
                Have questions, ideas, or simply want to discuss your digital
                needs? <br />
                Feel free to reach out to us using the contact information
                below.
              </p>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
              <div
                className="p-4 rounded contact-form"
                style={{
                  backgroundImage:
                    "url(../assets/img/cardImg.png)",
                  backgroundColor: "#000",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control py-3"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid gray",
                        color: "#fff",
                      }}
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control py-3"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid gray",
                        color: "#fff",
                      }}
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <textarea
                      className="w-100 form-control py-3"
                      rows="6"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid gray",
                        color: "#fff",
                      }}
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="text-start">
                    <button
                      className="btn custom-btn mt-3"
                      style={{ fontSize: "14px" }}
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsForm;
