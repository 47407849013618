import React, { useEffect, useState } from "react";

const AnimatedCounter = ({ targetNumber, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = Math.ceil(targetNumber / (duration / 10));
    
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber;
        } else {
          return prevCount + increment;
        }
      });
    }, 10); // Update interval

    return () => clearInterval(interval); // Cleanup
  }, [targetNumber, duration]);

  return <h5 className="card-title">{count.toLocaleString()} +</h5>;
};

export default AnimatedCounter;
