import React from "react";
import { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactUsForm from "./ContactUsForm";
import Modal from "./Modal";

const Home = () => {
  const [activeTab, setActiveTab] = useState("website"); // To manage active tab
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Website and Logo image arrays
  const websiteImages = [
    {
      imgSrc: "/assets/img/website1.png",
      title: "Startup Logo",
      subtitle: "Innovative Design",
    },
    {
      imgSrc: "/assets/img/website2.png",
      title: "Cyber Security",
      subtitle: "Cyber Security Core",
    },
    {
      imgSrc: "/assets/img/website3.png",
      title: "Mobile Info",
      subtitle: "Upcoming Phone",
    },
    {
      imgSrc: "/assets/img/website4.png",
      title: "Web Development",
      subtitle: "Web Analysis",
    },
  ];

  const logoImages = [
    {
      imgSrc: "assets/img/logo1.jpg",
      title: "Brand Logo",
      subtitle: "Creative Logo",
    },
    {
      imgSrc: "assets/img/logo2.png",
      title: "Startup Logo",
      subtitle: "Innovative Design",
    },
    {
      imgSrc: "assets/img/logo3.png",
      title: "Corporate Logo",
      subtitle: "Professional Logo",
    },
    {
      imgSrc: "assets/img/logo4.png",
      title: "Business Logo",
      subtitle: "Modern Logo",
    },
  ];

  const sectionStyle = {
    maxWidth: "600px",
  };

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Handle form submission logic here
  //   console.log("Name:", name);
  //   console.log("Email:", email);
  //   console.log("Message:", message);
  //   // Reset form fields
  //   setName("");
  //   setEmail("");
  //   setMessage("");
  // };

  return (
    <>
      <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <ol className="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="First slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="1"
              aria-label="Second slide"
            ></li>
          </ol>
          <div
            className="carousel-inner"
            role="listbox"
            style={{ backgroundColor: "black" }}
          >
            <div className="carousel-item active">
              <img
                src="assets/img/bgImg.png"
                className="img-fluid"
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1
                    className="text-white display-1 mb-4  animated fadeInRight main-heading lights-animation"
                    style={{ marginTop: "-100px", fontSize: "75px" }}
                  >
                    Vital Tech Solutions LTD
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Empowering businesses with cutting-edge design and
                    development solutions, Let's collaborate and transform your
                    vision into reality.
                  </p>
                  {/* <a href="" className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
            <div className="carousel-item" style={{ backgroundColor: "black" }}>
              <img
                src="assets/img/bgImg.png"
                className="img-fluid"
                alt="Second slide"
              />
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h1 className="text-white display-1 mb-4 animated fadeInLeft lights-animation">
                    An Innovative IT Company
                  </h1>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">
                    Driven by passion and fueled by creativity, With a
                    relentless commitment to innovation and excellence, we bring
                    your brand's vision to life through captivating designs.
                  </p>
                  {/* <a href="" className="me-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" className="ms-2"><button type="button" className="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="valuableSection"
          style={{ backgroundColor: "black" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="card" style={{ marginBottom: "10px" }}>
                  <div className="image-container">
                    <i
                      className="fa-solid fa-snowflake fa-3x"
                      style={{
                        color: "#98BC62",
                        transition: "color 0.3s ease",
                      }}
                    ></i>
                  </div>

                  <h5 className="card-title">Collaborative Innovation</h5>
                  <p className="card-description">
                    We work closely with our clients, listening to their goals,
                    challenges, and vision.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card" style={{ marginBottom: "5px" }}>
                  <div className="image-container">
                    <i
                      className="fa-solid fa-lightbulb fa-3x "
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>

                  <h5 className="card-title">Adjustable Approach</h5>
                  <p className="card-description">
                    We constantly adapt our strategies to stay ahead of digital
                    trends and technologies.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card" style={{ marginBottom: "5px" }}>
                  <div className="image-container">
                    <i
                      className="fa-solid fa-glass-water fa-3x "
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>

                  <h5 className="card-title">Achievement Oriented</h5>
                  <p className="card-description">
                    We measure our success by the tangible outcomes we achieve
                    for our clients.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="card" style={{ marginBottom: "5px" }}>
                  <div className="image-container">
                    <i
                      className="fa-solid fa-bolt-lightning fa-3x "
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>

                  <h5 className="card-title">Comprehensive Services</h5>
                  <p className="card-description">
                    From web development to digital marketing, our technical
                    expertise is second to none.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whoWeAreSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-left">
                <h2 className="main-heading">Who We Are</h2>
              </div>
              <div className="col-lg-12 text-left">
                <p className="sub-heading">
                  A Premier Digital Agency Delivering Brilliance Every Step Of
                  The Way
                </p>
              </div>
              <div
                className="col-lg-12"
                style={{ textAlign: "justify", marginBottom: "20px" }}
              >
                <p className="description">
                  At Vital Tech Solutions, we proudly stand as a premier digital
                  agency that consistently delivers brilliance at every step of
                  your digital journey. Our commitment to excellence isn’t just
                  a slogan; it’s a pledge that guides our daily efforts. Our
                  pursuit of brilliance starts with a dedicated team of
                  passionate experts. These individuals are the creative minds
                  and technical geniuses behind the innovative solutions and
                  captivating designs that define our work. Their unwavering
                  commitment to precision and creativity ensures that each
                  project we undertake is a masterpiece in its own right.
                  Innovation is the lifeblood of our agency. We understand that
                  the digital landscape is constantly evolving, and to stay
                  ahead, we relentlessly explore the latest technologies and
                  ideas. This innovative spirit allows us to craft solutions
                  that not only meet your current needs but also anticipate
                  future trends and challenges.
                </p>
              </div>
            </div>
          </div>
          {/* </section> */}
          {/* <section className="additionalInfoSection"> */}
          <div className="container additionalInfoSection">
            <div className="row">
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Complete control over the process
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ fontSize: "14px" }}
                      >
                        Vital Tech Solutions is a cutting-edge digital agency
                        dedicated to providing innovative solutions for your
                        online presence. Our expert team empowers your brand
                        with tailored strategies and flawless execution.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Freedom to drive your design vision
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ fontSize: "14px" }}
                      >
                        We offer you the freedom to drive your design vision.
                        Collaborate with our experts to bring your creative
                        ideas to life and transform your brand online.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        High quality products
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ fontSize: "14px" }}
                      >
                        At our digital agency, we pride ourselves on delivering
                        high-quality products that exceed your expectations. We
                        aim to elevate your digital presence with our expertise
                        and craftsmanship.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        100% satisfaction guarantee
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        style={{ fontSize: "14px" }}
                      >
                        We stand by our commitment to excellence with a 100%
                        satisfaction guarantee. With us, you get to experience
                        top-tier service and results that will leave you
                        delighted every time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="additional-content">
                  {/* <h4>Additional Information</h4> */}
                  <p>
                    Our approach revolves entirely around you, our valued
                    client. We prioritize understanding your specific needs,
                    objectives, and aspirations to tailor our solutions to your
                    vision. By collaborating closely with you, we ensure that
                    our strategies align seamlessly with your goals, creating a
                    partnership that focuses on your success. We don't just aim
                    to meet expectations; we deliver—whether that's through
                    increased website traffic, higher conversion rates, or
                    improved return on investment (ROI). Every solution we
                    provide is designed to make a measurable impact, driving
                    your business forward in a meaningful way. Our dedication to
                    excellence isn't a one-time effort; it's an ongoing journey.
                    We constantly strive for quality, innovation, and client
                    satisfaction, always seeking to raise the bar. we aim to set
                    new ones, pushing boundaries to deliver outstanding results
                    that truly matter to your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whoWeAreSection py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-2">
                <h2 className="main-heading">Services We Provide</h2>
              </div>
              <div className="col-12 text-center mb-4">
                <p className="sub-heading">
                  Our comprehensive range of services is designed to empower
                  your brand, enhance your online presence, and drive
                  exceptional results in the digital world.
                </p>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-gamepad fa-4x mb-4"
                        style={{ color: "#98BC62" }}
                      ></i>
                      <h4 className="mb-3" style={{ color: "white" }}>
                        Game Development
                      </h4>

                      <p className="mb-4" style={{ color: "white" }}>
                        We develop captivating games that entertain, engage, and
                        leave players craving for more immersive experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-laptop-code fa-4x mb-4 "
                        style={{ color: "#98BC62" }}
                      ></i>

                      <h4 className="mb-3" style={{ color: "white" }}>
                        App Development
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        We craft mobile applications that deliver functionality,
                        interactivity, and seamless experiences on various
                        devices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-file-code fa-4x mb-4 "
                        style={{ color: "#98BC62" }}
                      ></i>

                      <h4 className="mb-3" style={{ color: "white" }}>
                        Software Development
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        We provide tailored software development solutions to
                        streamline your operations and boost efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-magnifying-glass fa-4x mb-4"
                        style={{ color: "#98BC62" }}
                      ></i>

                      <h4 className="mb-3" style={{ color: "white" }}>
                        SEO & Content Writing
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        Our SEO and content writing services are designed to
                        optimize your online content and engage your audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-pen-nib fa-4x mb-4 "
                        style={{ color: "#98BC62" }}
                      ></i>
                      <h4 className="mb-3" style={{ color: "white" }}>
                        Graphic Designing
                      </h4>

                      <p className="mb-4" style={{ color: "white" }}>
                        We offer captivating graphic design services to visually
                        represent your brand and captivate your audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa fa-code fa-4x mb-4 "
                        style={{ color: "#98BC62" }}
                      ></i>
                      <h4 className="mb-3" style={{ color: "white" }}>
                        Web Design & Development
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        We build responsive, user-friendly websites that
                        showcase your brand and convert visitors into loyal
                        customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-magnifying-glass-chart fa-4x mb-4"
                        style={{ color: "#98BC62" }}
                      ></i>

                      <h4 className="mb-3" style={{ color: "white" }}>
                        Digital Marketing
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        We provide strategic digital marketing services to
                        enhance your online presence and drive business growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mb-4">
                <div
                  className="services-item bg-dark p-4 text-center"
                  style={{ scale: "0.95" }}
                >
                  <div className="services-content">
                    <div className="services-content-icon mb-3">
                      <i
                        className="fa-solid fa-pen-ruler fa-4x mb-4"
                        style={{ color: "#98BC62" }}
                      ></i>
                      <h4 className="mb-3" style={{ color: "white" }}>
                        UI/UX Design
                      </h4>
                      <p className="mb-4" style={{ color: "white" }}>
                        We provide UX/UI design services to create user-centric
                        interfaces that elevate digital experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="container-fluid project py-5 mb-5"
          style={{ backgroundColor: "#000" }}
        >
          <div className="container">
            <div
              className="text-center mx-auto pb-5 wow fadeIn"
              data-wow-delay=".3s"
              style={sectionStyle}
            >
              <h1 className="main-heading">Our Portfolio</h1>

              {/* Button Controls */}
              <div className="d-flex justify-content-center">
                <button
                  className={`blog-icon btn btn-secondary px-3 rounded-pill mx-2 ${
                    activeTab === "website" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("website")}
                >
                  Website
                </button>
                <button
                  className={`blog-icon btn btn-secondary px-3 rounded-pill mx-2 ${
                    activeTab === "logo" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("logo")}
                >
                  Logo
                </button>
              </div>
            </div>

            {/* Owl Carousel */}
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              autoplay
              autoplayTimeout={3000}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              {(activeTab === "website" ? logoImages : websiteImages).map(
                (project, index) => (
                  <div className="item" key={index}>
                    <div
                      className="project-item"
                      style={{
                        transition: "all .5s ease-in-out",
                      }}
                    >
                      <div className="project-img">
                        <img
                          src={project.imgSrc}
                          className="img-fluid w-100 rounded"
                          style={{
                            height: "300px",
                            objectFit: "cover",
                          }}
                          alt={project.title}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </OwlCarousel>
          </div>
        </div>
        <section style={{ backgroundColor: "#000", marginTop: "-50px" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-1">
                <h2 className="main-heading">
                  Ready to transform your digital presence?
                </h2>
              </div>
              <div className="col-12 text-center mb-1">
                <p
                  className="sub-heading"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  Whether you seek a captivating website, an innovative mobile
                  app, or a powerful digital marketing strategy, our team of
                  experts is here to transform your vision into real success.
                </p>
              </div>
              <div className="col-12 text-center mb-1">
                <button
                  type="button"
                  className="btn custom-btn"
                  onClick={openModal}
                >
                  Get A Quote
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="whoWeAreSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="main-heading">Our Testimonial</h2>
              </div>
              <div className="col-lg-12 text-center">
                <p className="sub-heading">What Our Client Saying!</p>
              </div>
            </div>
            <div className="container-fluid">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                autoplay={true}
                autoplayTimeout={3000}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                <div
                  className="testimonial-item border p-4"
                  style={{ backgroundColor: "#000" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src="assets/img/testimonial-1.jpg" alt="" />
                    </div>
                    <div className="ms-4">
                      <h4 className="text-secondary">John Doe</h4>
                      <p className="m-0 pb-3">Profession</p>
                      <div className="d-flex pe-5">
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top mt-4 pt-3">
                    <p className="mb-0">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content heret elit... .
                    </p>
                  </div>
                </div>
                <div
                  className="testimonial-item border p-4"
                  style={{ backgroundColor: "#000" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src="assets/img/testimonial-1.jpg" alt="" />
                    </div>
                    <div className="ms-4">
                      <h4 className="text-secondary">mike doe</h4>
                      <p className="m-0 pb-3">Manager at xyz</p>
                      <div className="d-flex pe-5">
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top mt-4 pt-3">
                    <p className="mb-0">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content heret elit... .
                    </p>
                  </div>
                </div>
                <div
                  className="testimonial-item border p-4"
                  style={{ backgroundColor: "#000" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src="assets/img/testimonial-2.jpg" alt="" />
                    </div>
                    <div className="ms-4">
                      <h4 className="text-secondary">Elezhibat</h4>
                      <p className="m-0 pb-3">Profession</p>
                      <div className="d-flex pe-5">
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                        <i
                          className="fas fa-star me-1 "
                          style={{ color: "yellow" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top mt-4 pt-3">
                    <p className="mb-0">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content heret elit...
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section> */}
        <ContactUsForm />
      </div>
      <Modal showModal={showModal} handleClose={closeModal} />
    </>
  );
};

export default Home;
