import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Styles for the active link
  const activeStyle = {
    // fontSize: "1.2rem",
    fontWeight: "bold",
    letterSpacing: "0.05rem",
    color: "#98BC62",
  };

  // Function to toggle the collapse
  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Function to close the collapse on mobile when a link is clicked
  const closeNavbar = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  return (
    <div className="container-fluid bg-primary">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark py-0">
          <div className="row w-100 align-items-center">
            {/* Logo Column */}
            <div className="col-lg-4 col-6 d-flex align-items-center">
              <Link to="/" className="navbar-brand mt-2">
                <img
                  src="assets/img/logo.png" // Update with your image path
                  alt="Vital Tech Sol"
                  style={{ maxWidth: "80px" }} // Adjust size as needed
                />
              </Link>
            </div>

            {/* Collapse Button for mobile */}
            <div className="col-6 d-lg-none text-end">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                aria-controls="navbarCollapse"
                aria-expanded={isNavOpen}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            {/* Navigation Column */}
            <div className={`col-lg-8 d-none d-lg-flex justify-content-center`}>
              <div
                className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}
                id="navbarCollapse"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-link"
                      style={location.pathname === "/" ? activeStyle : {}}
                      onClick={() => { closeNavbar(); }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="nav-link"
                      style={location.pathname === "/about" ? activeStyle : {}}
                      onClick={() => { closeNavbar(); }}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/services"
                      className="nav-link"
                      style={
                        location.pathname === "/services" ? activeStyle : {}
                      }
                      onClick={() => { closeNavbar(); }}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/careers"
                      className="nav-link"
                      style={
                        location.pathname === "/careers" ? activeStyle : {}
                      }
                      onClick={() => { closeNavbar(); }}
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      className="nav-link"
                      style={
                        location.pathname === "/contact" ? activeStyle : {}
                      }
                      onClick={() => { closeNavbar(); }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Collapse Menu for mobile */}
          <div
            className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}
            id="navbarCollapse"
          >
            <ul className="navbar-nav d-lg-none">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link"
                  style={location.pathname === "/" ? activeStyle : {}}
                  onClick={() => { closeNavbar(); }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/about"
                  className="nav-link"
                  style={location.pathname === "/about" ? activeStyle : {}}
                  onClick={() => { closeNavbar(); }}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/services"
                  className="nav-link"
                  style={
                    location.pathname === "/services" ? activeStyle : {}
                  }
                  onClick={() => { closeNavbar(); }}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                    <Link
                      to="/careers"
                      className="nav-link"
                      style={
                        location.pathname === "/careers" ? activeStyle : {}
                      }
                      onClick={() => { closeNavbar(); }}
                    >
                      Careers
                    </Link>
                  </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="nav-link"
                  style={
                    location.pathname === "/contact" ? activeStyle : {}
                  }
                  onClick={() => { closeNavbar(); }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
