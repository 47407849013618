import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ApplyNowModal from "./ApplyNowModal";

const Careers = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="container-fluid page-header page-headerCareers py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Careers
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  style={{
                    color: location.pathname === "/" ? "#98BC62" : "inherit",
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                  }}
                >
                  <strong>Home</strong>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/careers"
                  style={{
                    color:
                      location.pathname === "/careers" ? "#98BC62" : "inherit",
                    fontWeight:
                      location.pathname === "/careers" ? "bold" : "normal",
                  }}
                >
                  <strong>Careers</strong>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section class="whoWeAreSection">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-12"
              style={{ textAlign: "justify", marginBottom: "20px" }}
            >
              <p class="description">
                At Vital Tech Solutions LTD, we believe that a strong team is
                the backbone of every successful digital agency. Our team is a
                diverse and talented collective of creative minds, technical
                wizards, and digital strategists, each bringing a unique
                perspective to the table. United by a shared passion for
                innovation and excellence, we work together to transform your
                digital dreams into reality. Whether it's crafting cutting-edge
                websites, executing impactful digital marketing strategies, or
                developing custom software solutions, our team is committed to
                delivering results that exceed expectations and drive your
                business forward.
              </p>
            </div>
          </div>
        </div>
        {/* </section> */}
        {/* <section class="additionalInfoSection"> */}
        <div class="container additionalInfoSection">
          <div class="row">
            <div class="col-lg-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      style={{ color: "#98BC62" }}
                    >
                      Sales Management Trainee
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <h2 style={{ color: "white" }}>Introduction:</h2>
                      <p>
                        Join Vital Tech Solutions as a Sales Management Trainee,
                        where we provide comprehensive training, mentorship from
                        seasoned professionals, and clear career growth
                        opportunities. Immerse yourself in an innovative and
                        dynamic environment that fosters creativity and
                        continuous learning. If you're driven and ambitious,
                        embark on an exciting journey with us to shape the
                        future of sales management.
                      </p>
                      <h2 style={{ color: "white" }}>Responsibilities:</h2>
                      <p>As a Sales Management Trainee, you will:</p>
                      <ul>
                        <li>
                          Develop and maintain relationships with clients,
                          ensuring their needs are met effectively.
                        </li>
                        <li>
                          Collaborate with the sales team to achieve targets and
                          drive business growth.
                        </li>
                        <li>
                          Analyze market trends and customer needs to identify
                          sales opportunities.
                        </li>
                        <li>
                          Participate in sales presentations and negotiations
                          under the guidance of experienced professionals.
                        </li>
                        <li>
                          Continuously enhance product knowledge and sales
                          techniques through training and mentorship programs.
                        </li>
                      </ul>
                      <h2 style={{ color: "white" }}>Requirements:</h2>
                      <p>We are looking for candidates who possess:</p>
                      <ul>
                        <li>
                          A bachelor’s degree in Business, Marketing, or a
                          related field.
                        </li>
                        <li>Strong communication and interpersonal skills.</li>
                        <li>
                          A goal-oriented mindset and a passion for sales.
                        </li>
                        <li>
                          Ability to work collaboratively in a team environment.
                        </li>
                        <li>
                          Willingness to learn and adapt in a fast-paced sales
                          environment.
                        </li>
                      </ul>
                      <button
                        type="button"
                        class="btn custom-btn"
                        style={{ fontSize: "18px" }}
                        onClick={openModal}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Sales Support Specialist
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <h2 style={{ color: "white" }}>Introduction:</h2>
                      <p>
                        Join Vital Tech Solutions as a Sales Support Specialist!
                        Become an integral part of our sales team with
                        comprehensive training, mentorship from experts, and
                        clear career growth opportunities. In this role, you'll
                        ensure smooth sales processes and enhance customer
                        satisfaction in a dynamic, innovative environment. If
                        you're detail-oriented and proactive, we’d love to have
                        you on board to support and elevate our sales efforts.
                      </p>
                      <h2 style={{ color: "white" }}>Responsibilities:</h2>
                      <p>As a Sales Support Specialist, you will:</p>
                      <ul>
                        <li>
                          Provide troubleshooting assistance for customer
                          orders, account statuses and relevant problems
                        </li>
                        <li>Provide data and guides to help the sales team</li>
                        <li>
                          Manage sales tracking tools and report on important
                          information
                        </li>
                        <li>Keep record of sales trends.</li>
                        <li>
                          Liaise with account managers to develop specific sales
                          strategies
                        </li>
                        <li>
                          Stay up-to-date with new product and feature launches
                          and ensure sales team is on board
                        </li>
                        <li>
                          Review pending orders and specific customers requests
                          to ensure excellent customer service and customer
                          experience
                        </li>
                        <li>Suggest sales process improvements</li>
                      </ul>
                      <h2 style={{ color: "white" }}>Requirements:</h2>
                      <p>We are looking for candidates who possess:</p>
                      <ul>
                        <li>
                          BS degree in Marketing or associates degree in
                          relevant field a plus
                        </li>
                        <li>
                          Proven work experience as a Sales support specialist
                          or Sales support associate
                        </li>
                        <li>Hands on experience with ERP and CRM systems</li>
                        <li>
                          Proficiency with MS Office Suite, particularly MS
                          Excel
                        </li>
                        <li>
                          In-depth understanding of sales principles and
                          customer service practices
                        </li>
                        <li>Excellent communication skills</li>
                        <li>Analytical and multitasking skills</li>
                        <li>Teamwork and motivational skills</li>
                      </ul>
                      <button
                        type="button"
                        class="btn custom-btn"
                        style={{ fontSize: "18px" }}
                        onClick={openModal}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Account Officer
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <h2 style={{ color: "white" }}>Introduction:</h2>
                      <p>
                        Join Vital Tech Solutions as an Account Officer! Step
                        into a pivotal role with Vital Tech Solutions where
                        you’ll manage client accounts with precision and care.
                        As an Account Officer, you’ll receive thorough training
                        and mentorship from industry experts, with clear paths
                        for career advancement. Work in a dynamic and supportive
                        environment that values innovation and continuous
                        improvement. If you're organized, client-focused, and
                        eager to drive account success, we invite you to be part
                        of our mission to excel in account management.
                      </p>
                      <h2 style={{ color: "white" }}>Responsibilities:</h2>
                      <p>As an Account Officer, you will:</p>
                      <ul>
                        <li>
                          Develop and maintain relationships with clients,
                          ensuring their needs are met effectively.
                        </li>
                        <li>
                          Manage Client Accounts: Oversee and maintain client
                          accounts, ensuring accurate and timely management of
                          account details.
                        </li>
                        <li>
                          Client Communication: Act as the primary point of
                          contact for clients, addressing inquiries, resolving
                          issues, and maintaining positive relationships.
                        </li>
                        <li>
                          Account Administration: Handle administrative tasks
                          such as account setup, updates, and documentation.
                        </li>
                        <li>
                          Monitor Account Performance: Track and analyze account
                          performance metrics to ensure client satisfaction and
                          account growth.
                        </li>
                        <li>
                          Prepare Reports: Generate and present regular reports
                          on account status, performance, and any relevant
                          insights to clients and internal teams.
                        </li>
                        <li>
                          Coordinate with Internal Teams: Collaborate with
                          sales, finance, and other departments to ensure
                          cohesive account management and problem resolution.
                        </li>
                        <li>
                          Process Transactions: Manage and process account
                          transactions, including billing, payments, and
                          adjustments.
                        </li>
                        <li>
                          Client Onboarding: Facilitate the onboarding process
                          for new clients, ensuring a smooth transition and
                          setup.
                        </li>
                        <li>
                          Resolve Discrepancies: Investigate and resolve any
                          discrepancies or issues related to accounts.
                        </li>
                        <li>
                          Compliance: Ensure all account management practices
                          comply with company policies and regulatory
                          requirements.
                        </li>
                      </ul>
                      <h2 style={{ color: "white" }}>Requirements:</h2>
                      <p>We are looking for candidates who possess:</p>
                      <ul>
                        <li>
                          Bachelor’s degree in Business Administration, Finance,
                          Accounting, or a related field.
                        </li>
                        <li>
                          Relevant experience in account management, customer
                          service, or a similar role is preferred.
                        </li>
                        <li>
                          Excellent verbal and written communication skills to
                          effectively interact with clients and internal teams.
                        </li>
                        <li>
                          Strong attention to detail to accurately manage
                          account information and handle transactions.
                        </li>
                        <li>
                          Ability to manage multiple accounts and tasks
                          simultaneously, with strong organizational and
                          time-management skills.
                        </li>
                        <li>
                          Proficiency in identifying issues and providing
                          effective solutions.
                        </li>
                        <li>
                          Familiarity with account management software, CRM
                          systems, and Microsoft Office Suite (Excel, Word,
                          PowerPoint).
                        </li>
                        <li>
                          Strong interpersonal skills to build and maintain
                          positive relationships with clients.
                        </li>
                        <li>
                          Ability to analyze account data and generate
                          meaningful insights and reports.
                        </li>
                        <li>
                          Commitment to delivering high-quality customer service
                          and maintaining client satisfaction.
                        </li>
                        <li>
                          Ability to adapt to changing client needs and industry
                          trends.
                        </li>
                        <li>
                          Understanding of relevant compliance and regulatory
                          requirements related to account management.
                        </li>
                      </ul>
                      <button
                        type="button"
                        class="btn custom-btn"
                        style={{ fontSize: "18px" }}
                        onClick={openModal}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Senior Social Media and Marketing Executive
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <h2 style={{ color: "white" }}>Introduction:</h2>
                      <p>
                        At Vital Tech Solutions, we are seeking a seasoned
                        professional to take on the role of Senior Account
                        Officer. In this position, you'll lead the management of
                        our client accounts with a strategic approach,
                        leveraging your expertise to drive account success and
                        foster strong client relationships. You'll receive
                        ongoing support and development opportunities while
                        working in an innovative and dynamic environment. If
                        you're a proactive, detail-oriented individual with a
                        passion for excellence in account management, we invite
                        you to contribute to our mission and shape the future of
                        client services.
                      </p>
                      <h2 style={{ color: "white" }}>Responsibilities:</h2>
                      <p>
                        As a Social Media and Marketing Executive, you will:
                      </p>
                      <ul>
                        <li>
                          Strategic Account Management: Develop and execute
                          strategic plans to drive growth and success for
                          high-value client accounts.
                        </li>
                        <li>
                          Client Relationship Management: Build and maintain
                          strong, long-term relationships with key clients,
                          ensuring their needs are met and expectations are
                          exceeded.
                        </li>
                        <li>
                          Performance Monitoring: Analyze account performance
                          metrics to provide actionable insights and
                          recommendations for improvement.
                        </li>
                        <li>
                          Problem Resolution: Address and resolve client issues
                          and conflicts promptly to ensure client satisfaction.
                        </li>
                        <li>
                          Reporting and Communication: Prepare and deliver
                          detailed reports on account status and performance to
                          clients and internal teams.
                        </li>
                      </ul>
                      <h2 style={{ color: "white" }}>Requirements:</h2>
                      <p>We are looking for candidates who possess:</p>
                      <ul>
                        <li>
                          Several years of experience in account management with
                          a proven track record.
                        </li>
                        <li>Ability to lead and mentor junior staff.</li>
                        <li>
                          Strong verbal and written communication for client and
                          team interactions.
                        </li>
                        <li>
                          Proficiency in analyzing account performance and
                          providing strategic recommendations.
                        </li>
                        <li>Effective at resolving complex client issues.</li>
                      </ul>
                      <button
                        type="button"
                        class="btn custom-btn"
                        style={{ fontSize: "18px" }}
                        onClick={openModal}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="whoWeAreSection">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h2 class="main-heading">Our recruitment process</h2>
            </div>
            <div class="col-lg-12 text-center">
              <p class="sub-heading" style={{ color: "white" }}>
                Vital Tech Solutions aims to build high-quality software while
                also fostering an enjoyable work environment that allows
                everyone the opportunity to reach their highest potential.
              </p>
            </div>
          </div>
        </div>
        <section
          class="valuableSection"
          style={{ backgroundColor: "black", marginTop: "50px" }}
        >
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <div class="" style={{ marginBottom: "10px", height: "300px" }}>
                  <div class="image-container" style={{ marginBottom: "80px" }}>
                    <i
                      class="fa-regular fa-file fa-3x "
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>
                  <h5 class="card-title">Application</h5>
                  <p class="card-description">
                    Submit your application through our website. Your
                    application is being read by real humans.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="" style={{ marginBottom: "10px", height: "300px" }}>
                  <div class="image-container" style={{ marginBottom: "80px" }}>
                    <i
                      class="fa-regular fa-comments fa-3x "
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>

                  <h5 class="card-title">Interview</h5>
                  <p class="card-description">
                    Let us get familiar with your competencies and experience in
                    the field.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="" style={{ marginBottom: "10px", height: "300px" }}>
                  <div class="image-container" style={{ marginBottom: "80px" }}>
                    <i
                      class="fa-solid fa-briefcase fa-3x"
                      style={{ color: "#98BC62" }}
                    ></i>
                  </div>
                  <h5 class="card-title">Offer</h5>
                  <p class="card-description">
                    Let us get familiar with your competencies and experience in
                    the field.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <ApplyNowModal showModal={showModal} handleClose={closeModal} />
    </>
  );
};

export default Careers;
