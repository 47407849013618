import React from "react";
import { Link, useLocation } from "react-router-dom";
import ContactUsForm from "./ContactUsForm";

const Contact = () => {
  const location = useLocation();
  return (
    <>
      <div className="container-fluid page-header page-headerContact py-5">
        <div className="container text-center py-5 mt-5 ">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  style={{
                    color: location.pathname === "/" ? "#98BC62" : "inherit",
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                  }}
                >
                  <strong>Home</strong>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/contact"
                  style={{
                    color:
                      location.pathname === "/contact" ? "#98BC62" : "inherit",
                    fontWeight:
                      location.pathname === "/contact" ? "bold" : "normal",
                  }}
                >
                  <strong>Contact</strong>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* calling ContactUsForm */}
      <ContactUsForm />
    </>
  );
};

export default Contact;
