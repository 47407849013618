import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Modal from "./Modal";

const Services = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="container-fluid page-header page-headerServices py-5">
        <div className="container text-center py-5">
          <h1 className="display-2 text-white mb-4 animated slideInDown">
            Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link
                  to="/"
                  style={{
                    color: location.pathname === "/" ? "#98BC62" : "inherit",
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                  }}
                >
                  <strong>Home</strong>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to="/Services"
                  style={{
                    color:
                      location.pathname === "/services" ? "#98BC62" : "inherit",
                    fontWeight:
                      location.pathname === "/services" ? "bold" : "normal",
                  }}
                >
                  <strong>Services</strong>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="whoWeAreSection py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <h2 className="main-heading">Services We Provide</h2>
            </div>
            <div className="col-12 text-center mb-4">
              <p className="sub-heading">
                Our comprehensive range of services is designed to empower your
                brand, enhance your online presence, and drive exceptional
                results in the digital world.
              </p>
            </div>

            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-gamepad fa-4x mb-4"
                      style={{ color: "#98BC62" }}
                    ></i>
                    <h4 className="mb-3" style={{ color: "white" }}>
                      Game Development
                    </h4>

                    <p className="mb-4" style={{ color: "white" }}>
                      We develop captivating games that entertain, engage, and
                      leave players craving for more immersive experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-laptop-code fa-4x mb-4 "
                      style={{ color: "#98BC62" }}
                    ></i>

                    <h4 className="mb-3" style={{ color: "white" }}>
                      App Development
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      We craft mobile applications that deliver functionality,
                      interactivity, and seamless experiences on various
                      devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-file-code fa-4x mb-4 "
                      style={{ color: "#98BC62" }}
                    ></i>

                    <h4 className="mb-3" style={{ color: "white" }}>
                      Software Development
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      We provide tailored software development solutions to
                      streamline your operations and boost efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-magnifying-glass fa-4x mb-4"
                      style={{ color: "#98BC62" }}
                    ></i>

                    <h4 className="mb-3" style={{ color: "white" }}>
                      SEO & Content Writing
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      Our SEO and content writing services are designed to
                      optimize your online content and engage your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-pen-nib fa-4x mb-4 "
                      style={{ color: "#98BC62" }}
                    ></i>
                    <h4 className="mb-3" style={{ color: "white" }}>
                      Graphic Designing
                    </h4>

                    <p className="mb-4" style={{ color: "white" }}>
                      We offer captivating graphic design services to visually
                      represent your brand and captivate your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa fa-code fa-4x mb-4 "
                      style={{ color: "#98BC62" }}
                    ></i>
                    <h4 className="mb-3" style={{ color: "white" }}>
                      Web Design & Development
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      We build responsive, user-friendly websites that showcase
                      your brand and convert visitors into loyal customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-magnifying-glass-chart fa-4x mb-4"
                      style={{ color: "#98BC62" }}
                    ></i>

                    <h4 className="mb-3" style={{ color: "white" }}>
                      Digital Marketing
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      We provide strategic digital marketing services to enhance
                      your online presence and drive business growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 mb-4">
              <div
                className="services-item bg-dark p-4 text-center"
                style={{ scale: "0.95" }}
              >
                <div className="services-content">
                  <div className="services-content-icon mb-3">
                    <i
                      className="fa-solid fa-pen-ruler fa-4x mb-4"
                      style={{ color: "#98BC62" }}
                    ></i>
                    <h4 className="mb-3" style={{ color: "white" }}>
                      UI/UX Design
                    </h4>
                    <p className="mb-4" style={{ color: "white" }}>
                      We provide UX/UI design services to create user-centric
                      interfaces that elevate digital experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#000" }}>
        <div
          className="container"
          style={{ paddingTop: "50px", paddingBottom: "50px" }}
        >
          <div className="row">
            <div className="col-12 text-center mb-1">
              <h2 className="main-heading">
                Ready to transform your digital presence?
              </h2>
            </div>
            <div className="col-12 text-center mb-1">
              <p
                className="sub-heading"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                Whether you seek a captivating website, an innovative mobile
                app, or a powerful digital marketing strategy, our team of
                experts is here to transform your vision into real success.
              </p>
            </div>
            <div className="col-12 text-center mb-1">
              <button
                type="button"
                className="btn custom-btn"
                onClick={openModal}
              >
                Get A Quote
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal showModal={showModal} handleClose={closeModal} />
    </>
  );
};

export default Services;
