import React, { useState } from "react";
import { sendEmail } from "../Email Services/Email";
import Swal from "sweetalert2";

const ApplyNowModal = ({ showModal, handleClose }) => {
  const [emp_first_name, setEmp_first_name] = useState("");
  const [emp_last_name, setEmp_last_name] = useState("");
  const [emp_email, setEmp_email] = useState("");
  const [emp_phoneNum, setEmp_phoneNum] = useState("");
  const [fileAttachment, setFileAttachment] = useState(null);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleFileUpload = (event) => {
    setFileAttachment(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      emp_first_name,
      emp_last_name,
      emp_email,
      emp_phoneNum,
      message,
      fileAttachment,
    };

    sendEmail(templateParams)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setStatus("Email sent successfully!");

        Swal.fire({
          icon: "success",
          title: "Thank you!",
          text: "Your Form has been submitted!",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline",
          },
          background: "#000",
          color: "#fff",
          confirmButtonColor: "#98BC62",
        });

        setEmp_first_name("");
        setEmp_last_name("");
        setEmp_email("");
        setEmp_phoneNum("");
        setMessage("");
        setFileAttachment(null);
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setStatus("Failed to send email. Please try again later.");

        Swal.fire({
          icon: "error",
          title: "Failed to Send",
          text: "Failed to send email. Please try again later.",
          confirmButtonText: "Ok",
          customClass: {
            popup: "custom-popup-inline",
          },
        });
      });
  };

  if (!showModal) return null;

  // Inline styles for the modal
  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    background: "#000",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "900px",
    width: "100%",
    position: "relative",
    border: "2px solid #98BC62",
  };

  const modalCloseBtnStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    border: "none",
    background: "transparent",
    fontSize: "24px",
    cursor: "pointer",
    color: "#fff",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    alignItems: "center",
  };

  const modalSubheadingStyle = {
    textAlign: "center",
    marginBottom: "25px",
    fontSize: "20px",
    color: "#fff",
    fontFamily: "Calibri",
  };

  const formStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px", // Adds space between fields
    justifyContent: "center", 
  };

  const formGroupStyle = {
    flex: "1 1 calc(50% - 20px)", // Takes up 50% width minus the gap
    marginBottom: "20px",
  };

  const formControlStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid gray",
    borderRadius: "4px",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "16px",
    outline: "none",
    transition: "border-color 0.3s ease",
  };

  const fileInputStyle = {
    display: "block",
    backgroundColor: "#000",
    border: "1px solid gray",
    borderRadius: "4px",
    padding: "10px",
    color: "#fff",
    cursor: "pointer",
  };

  const btnPrimaryStyle = {
    backgroundColor: "transparent",
    color: "#fff",
    padding: "10px 20px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    border: "2px solid #98BC62",
    borderRadius: "10px",
    marginTop: "-40px",
  };

  const btnPrimaryHoverStyle = {
    backgroundColor: "#98BC62",
  };

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <button style={modalCloseBtnStyle} onClick={handleClose}>
          &times;
        </button>
        <h6 style={modalSubheadingStyle}>
          To apply for this exciting opportunity, please submit your resume and a
          cover letter detailing your relevant experience.
        </h6>
        <form style={formStyle} onSubmit={handleSubmit} encType="multipart/form-data">
          <div style={formGroupStyle}>
            <input
              type="text"
              style={formControlStyle}
              placeholder="First Name"
              value={emp_first_name}
              onChange={(e) => setEmp_first_name(e.target.value)}
              required
            />
          </div>
          <div style={formGroupStyle}>
            <input
              type="text"
              style={formControlStyle}
              placeholder="Last Name"
              value={emp_last_name}
              onChange={(e) => setEmp_last_name(e.target.value)}
              required
            />
          </div>
          <div style={formGroupStyle}>
            <input
              type="email"
              style={formControlStyle}
              placeholder="Email Address"
              value={emp_email}
              onChange={(e) => setEmp_email(e.target.value)}
              required
            />
          </div>
          <div style={formGroupStyle}>
            <input
              type="tel"
              style={formControlStyle}
              placeholder="Phone Number"
              value={emp_phoneNum}
              onChange={(e) => setEmp_phoneNum(e.target.value)}
              required
            />
          </div>
          <div style={formGroupStyle}>
            <textarea
              style={formControlStyle}
              placeholder="Message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <div style={formGroupStyle}>
            <input
              type="file"
              style={fileInputStyle}
              onChange={handleFileUpload}
            />
          </div>
          <div style={buttonContainerStyle}>
            <button
              type="submit"
              style={btnPrimaryStyle}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = btnPrimaryHoverStyle.backgroundColor)}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            >
              Submit
            </button>
          </div>
        </form>
        <div>
          <p>{status}</p>
        </div>
      </div>
    </div>
  );
};

export default ApplyNowModal;
