import emailjs from 'emailjs-com';

/**
 * Sends an email using EmailJS with optional file attachment.
 * @param {Object} templateParams - The email template parameters.
 * @param {string} templateParams.emp_first_name - The sender's first name.
 * @param {string} templateParams.emp_last_name - The sender's last name.
 * @param {string} templateParams.emp_email - The sender's email.
 * @param {string} templateParams.emp_phoneNum - The sender's phone number.
 * @param {string} templateParams.message - The email message content.
 * @param {File} [templateParams.fileAttachment] - The file to attach.
 * @returns {Promise} - The promise that resolves to the email sending result.
 */
export const sendEmail = (templateParams) => {
  return new Promise((resolve, reject) => {
    if (templateParams.fileAttachment) {
      const reader = new FileReader();
      
      // Convert the file to Base64
      reader.onload = function () {
        const base64String = reader.result.split(',')[1];
        const attachment = {
          filename: templateParams.fileAttachment.name,
          content: base64String,
          encoding: 'base64',
        };

        emailjs.send(
          "service_bnmh288", // Your EmailJS Service ID
          "template_j3cwo2n", // Your EmailJS Template ID
          {
            ...templateParams,
            attachment,
          },
          "taC-fZ-Ep16QPj-jR" // Your EmailJS User ID
        ).then(resolve).catch(reject);
      };

      // Read the file as Data URL (Base64)
      reader.onerror = reject;
      reader.readAsDataURL(templateParams.fileAttachment);
    } else {
      // Send the email without attachment
      emailjs.send(
        "service_bnmh288", // Your EmailJS Service ID
        "template_j3cwo2n", // Your EmailJS Template ID
        templateParams,
        "taC-fZ-Ep16QPj-jR" // Your EmailJS User ID
      ).then(resolve).catch(reject);
    }
  });
};